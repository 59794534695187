import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Front Squat 1-1-1-1 to a 1RM`}</p>
    <p>{`Power Clean 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`T.U.P.`}</strong></p>
    <p>{`15-12-9-6-3 reps each of:`}</p>
    <p>{`Power Cleans (135/95)`}</p>
    <p>{`Pullups`}</p>
    <p>{`Front Squats (135/95)`}</p>
    <p>{`Pullups`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`compare to 12/9/15`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at East and The Ville so bring a
friend! 8 & 9am at The Ville, 10 & 11am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On Saturday, October 28th The Ville will be hosting a fundraising
wod for the family of LMPD Officer Jason Schweitzer who was tragically
killed by a drunk driver in Lexington, Ky.  You can donate and purchase
a shirt at the link below.  CrossFit 222 will show up as the host but
you can choose your shirt size and our location when checking out.  The
shirts will be brought to The Ville for you.  Please sign up soon and
show your support for one of our LMPD officers and his family!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.
Truegritcomp.wordpress.com.  Workout 1 is posted, WOD 2 to post tonight!
 If you’d like to be on a team but don’t have teammates please email
Daniel.  You can also add your name `}<strong parentName="p">{`**`}</strong>{`to the board in back at the
Ville if you’d like to help judge or volunteer. ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      